<template>
  <div>
    <div v-if="checkoutStatus === 'success'" class="m-5 text-center">
      <div class="mb-3">
        Payment is successful! Please allow 24hr for the update to take effect.
      </div>
      <a href="/" class="btn btn-primary">Back to Trainifier</a>
    </div>

    <div v-else-if="checkoutStatus === 'abort'" class="m-5 text-center">
      <div class="mb-3">
        Payment aborted.
      </div>
      <a href="/" class="btn btn-primary">Back to Trainifier</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionCheckout',
  computed: {
    checkoutStatus() {
      return this.$route.query.status;
    },
  },
  mounted() {
    if (!this.checkoutStatus) {
      window.location.href = '/';
    }
  },
};
</script>

<style lang="scss">
html, body {
  height: inherit;
}

#app > nav {
  display: none;
  pointer-events: none;
}
</style>
